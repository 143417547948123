<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {},
    es: {},
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      total: {
        contracts: "***,** BRL",
        profits: "***,** BRL",
        withdrawals: "R -,--",
        balance: "***,** BRL",
      },

      statData: null,

      referral: process.env.VUE_APP_URL_PAINEL + this.$store.state.account.user.username
    };
  },
  methods: {
    getTotalInvestiments() {
      api
        .get('investments/contracts/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.contracts = this.$options.filters.currency(response.data.total.contracts)
            this.total.profits   = this.$options.filters.currency(response.data.total.profits)
            this.getStatData();
          }
        })
    },
    getTotalWithdrawals() {
      api
        .get('wallet/withdrawal/total')
        .then(response => {
          if (response.data.status=='success') {
            this.total.withdrawals = this.$options.filters.currency(response.data.total)
            this.getStatData();
          }
        })
    },
    getTotalBalance() {
      api
        .get('wallet/balance')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance = this.$options.filters.currency(response.data.balance)
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          title: "Saldo Disponível",
          value: this.total.balance
        },
        {
          title: "Total Aportado",
          value: this.total.contracts,
        },
        {
          title: "Rendimentos Totais",
          value: this.total.profits,
        },
      ]
    },
  },
  mounted() {
    this.getTotalInvestiments()
    this.getTotalWithdrawals()
    this.getTotalBalance()
    this.getStatData()
  }
};
</script>

<template>
  <Layout>
    <Banners></Banners>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h5>Bem-vindo à Roodar</h5>
        <p>Confira um resumo da sua conta.</p>
      </div>
    </div>

    <div class="card" v-if="account.user.package.sponsored == 'yes'">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          Link de indicação
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="referral">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="referral
            " v-on:click="$toast.success('Copiado!');">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>